<template>
    <div class="chart-wrapper">
        <div class="l-padded datepicker">
            <date-range-input :value="customRange" @input="loadHistory" />
        </div>

        <div class="chart">
            <div v-if="isFilterVisisble" class="l-stack l-gap-1 filter">
                <base-multiselect
                    v-model="filterAssets"
                    :placeholder="$t('shared.assets')"
                    :options="trackers"
                    track-by="id"
                    :custom-label="option => option.asset_details.name"
                    multiple
                />

                <base-multiselect
                    v-model="filterLocations"
                    :placeholder="$t('locations')"
                    :options="getLocationsSortedByName"
                    track-by="id"
                    label="name"
                    multiple
                />

                <base-button
                    size="small"
                    @click="isAssetsMainAxis = !isAssetsMainAxis"
                >
                    {{ $t('switchAxes') }}
                </base-button>
            </div>

            <div v-if="isLoading" class="l-stack l-center l-padded">
                <v-spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <apex-chart
                v-else
                :height="chartHeight"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { formatHelper, httpHelper } from '@/utils'

import BaseButton from '@/components/redesigned/BaseButton'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect'
import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'

export default {
    name: 'LocationHistoryChartView',
    components: {
        ApexChart,
        BaseButton,
        BaseMultiselect,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper],
    props: {
        fullHeight: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            responseData: [],
            isFilterVisisble: false,
            isAssetsMainAxis: true,
            filterAssets: [],
            filterLocations: [],
            series: [],
            chartOptions: {
                chart: {
                    type: 'rangeBar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: ([start, end]) =>
                        formatHelper.hoursAndMinutesDuration(
                            moment.duration(end - start).asSeconds()
                        ),
                },
                tooltip: {
                    x: {
                        format: 'dd.MM.yyyy HH:mm:ss',
                    },
                    y: {
                        formatter: (_, { dataPointIndex, seriesIndex }) => {
                            const [start, end] = this.series[seriesIndex].data[
                                dataPointIndex
                            ].y
                            return `${formatHelper.hoursAndMinutesDuration(
                                moment.duration(end - start).asSeconds()
                            )}:`
                        },
                    },
                },
                xaxis: {
                    type: 'datetime',
                },
                yaxis: {
                    showForNullSeries: false,
                    labels: {
                        formatter: value => {
                            const duration = this.series.reduce(
                                (total, asset) => {
                                    const current = asset.data
                                        .filter(({ x }) => x === value)
                                        .reduce((time, { y: [start, end] }) => {
                                            return time + end - start
                                        }, 0)

                                    return total + current
                                },
                                0
                            )

                            return duration
                                ? [
                                      value,
                                      `(${formatHelper.hoursAndMinutesDuration(
                                          duration / 1000
                                      )})`,
                                  ]
                                : value
                        },
                    },
                },
                legend: {
                    showForSingleSeries: true,
                    showForNullSeries: false,
                    showForZeroSeries: false,
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    computed: {
        ...mapState('tracker', ['assetTypes', 'trackers']),
        ...mapGetters('locations', ['getLocationsSortedByName']),
        chartHeight() {
            return this.fullHeight
                ? document.documentElement.clientHeight - 320
                : 400
        },
    },
    watch: {
        filterAssets() {
            this.loadHistory()
        },
        filterLocations() {
            this.loadHistory()
        },
        isAssetsMainAxis() {
            this.renderData()
        },
    },
    created() {
        moment.locale(this.$i18n.locale)
    },
    mounted() {
        if (this.$route.name === 'assetLocationHistoryChart') {
            const id = +this.$route.params.id
            this.filterAssets = [this.trackers.find(item => item.id === id)]
        } else if (this.$route.name === 'locationHistoryChart') {
            const id = +this.$route.params.id
            this.filterLocations = [
                this.getLocationsSortedByName.find(item => item.id === id),
            ]
            this.isAssetsMainAxis = false
        } else {
            this.isFilterVisisble = true
        }
    },
    methods: {
        async loadHistory(dates) {
            this.isLoading = true
            if (dates) {
                this.startDate = moment(dates.startDate)
                this.endDate = moment(dates.endDate)
                this.customRange = {
                    startDate: this.startDate.toDate(),
                    endDate: this.endDate.toDate(),
                }
            }
            await this.loadData()
            this.isLoading = false
        },
        async loadData() {
            const filterQueryParams =
                this.filterAssets
                    .map(asset => `&asset=${asset.asset}`)
                    .join('') +
                this.filterLocations
                    .map(location => `&location=${location.id}`)
                    .join('')

            if (!filterQueryParams) {
                this.series = []
                return
            }

            const results = []

            let url =
                'location-history/?' +
                `start=${encodeURIComponent(this.startDate.format())}` +
                `&end=${encodeURIComponent(this.endDate.format())}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}` +
                filterQueryParams

            while (url) {
                const { data } = await httpHelper.get(url)
                results.push(...data.results)
                url = data.next
            }

            this.responseData = results
            this.renderData()
        },
        renderData() {
            const [x, y] = this.isAssetsMainAxis
                ? ['asset', 'location']
                : ['location', 'asset']
            const [getXName, getYName] = this.isAssetsMainAxis
                ? [this.getAssetName, this.getLocationName]
                : [this.getLocationName, this.getAssetName]

            this.series = this.responseData.reduce((acc, cur) => {
                const bucket = acc.find(item => item[x] === cur[x])
                const dataItem = {
                    x: getYName(cur[y]),
                    y: [
                        new Date(cur.start).getTime(),
                        new Date(cur.end).getTime(),
                    ],
                }

                if (bucket) {
                    bucket.data.push(dataItem)
                } else {
                    acc.push({
                        [x]: cur[x],
                        name: getXName(cur[x]),
                        data: [dataItem],
                    })
                }

                return acc
            }, [])
        },
        getAssetName(assetId) {
            return this.trackers.find(item => item.asset === assetId)
                ?.asset_details?.name
        },
        getLocationName(locationId) {
            return this.getLocationsSortedByName.find(
                item => item.id === locationId
            )?.name
        },
    },
}
</script>

<i18n>
{
    "en": {
        "locations": "Locations",
        "nHours": "{n} hour | {n} hours",
        "switchAxes": "Switch axes"
    },
    "de": {
        "locations": "Standorte",
        "nHours": "{n} Stunde | {n} Stunden",
        "switchAxes": "Achsen tauschen"
    },
    "it": {
        "locations": "Locatione",
        "nHours": "{n} ora | {n} ore",
        "switchAxes": "Scambiare gli assi"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-wrapper {
    display: flex;
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
}

.filter {
    margin-left: 1rem;
    button {
        max-width: 120px;
    }
}

.datepicker {
    width: 20%;
    min-width: 350px;
}

@include respond-to('for-tablet-down') {
    .chart-wrapper {
        display: block;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }

    .filter {
        margin-bottom: 1rem;
        margin-left: 1rem;
    }

    .chart {
        border: none;
    }
}
</style>
